<template>
  <div>
    <CRow>
      <CCol sm="2">
        <!-- <CCard>
            <CCardHeader>
              <strong>Pencarian </strong>
              <a href="/#/asetwilayah/list" class="float-right btn btn-secondary form-control-sm">Kembali</a> --
            </CCardHeader>
            <CCardBody class="p-2">
              
        <div class="p-2">          
          <select class="form-control" v-model='kabkota'>
            <option v-for='opt in kelurahans' v-bind:value="opt">{{opt}}</option>
          </select>
        </div>
        <div class="mb-1">
          <CInput
                    placeholder="Cari Via Nama Barang"
                    autofocus = "true"
                    autocomplete="username" 
                    class="form-control-sm"
                    />
        </div>
        <div class="mb-1">
          <CInput
                    placeholder="Cari Via Instansi"
                    autofocus = "true"
                    autocomplete="username" 
                    class="form-control-sm"
                    />
        </div>
        <div class="mb-1">
          <CInput
                    placeholder="Cari Via Jenis Hak"
                    autofocus = "true"
                    autocomplete="username" 
                    class="form-control-sm"
                    />
        </div>
        <div class="mb-1">
          <CInput
                    placeholder="Cari Via No Hak"
                    autofocus = "true"
                    autocomplete="username" 
                    class="form-control-sm"
                    />
        </div>
        <div class="mb-1">
          <CInput
                    placeholder="Cari Via Alamat"
                    autofocus = "true"
                    autocomplete="username" 
                    class="form-control-sm"
                    />
        </div>
        <div class="mb-1">
          <CCol class='float-right text-right'>
            <hr>
            <CButton color="secondary" @click="reset()" class='mr-3'>Reset</CButton>
            <CButton color="success" @click="cariData()">Cari Peta</CButton>
          </CCol>
        </div>
      </CCardBody>
        </CCard> -->
        <CCard>
            <CCardHeader>
              <strong>Pilih Layer </strong>
              <!-- <a href="/#/asetwilayah/list" class="float-right btn btn-secondary form-control-sm">Kembali</a> -->
            </CCardHeader>
            <CCardBody class="p-2">
              <div style="min-height: 100px;width: 100%;" >
                <div class="form-check m-2">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked>
                  <label class="form-check-label" for="flexCheckDefault">
                    Batas Kecamatan
                  </label><hr>
                </div>
                <div class="form-check m-2">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" checked  @input="addLayer('bersertifikat', 'https://sigagah.s3.ap-southeast-1.amazonaws.com/data_baru.geojson','#009900','#009900',1)">
                  <label class="form-check-label" for="flexCheckChecked">
                    Aset Bersertifikat
                  </label>
                  <hr>
                </div>
                <div class="form-check m-2">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked3" @input="addLayer('belumbersertifikat', 'https://sigagah.s3.ap-southeast-1.amazonaws.com/data_baru.geojson','#CC0000','#CC0000',1)">
                  <label class="form-check-label" for="flexCheckChecked">
                    Aset Belum Bersertifikat
                  </label>
                  <hr>
                </div>
                <div class="form-check m-2">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked4" @input="addLayer('hakdiatashak', 'https://sigagah.s3.ap-southeast-1.amazonaws.com/hakdiatashak.geojson','#DA7F2D','#DA7F2D',1)">
                  <label class="form-check-label" for="flexCheckChecked">
                    Hak diatas Hak
                  </label>
                </div>
              </div>
            </CCardBody>
          </CCard>
      </CCol>
      <CCol sm="10">
            <div  class="mapboxgl-map"  style="height: 800px;width: 100%;padding:0;margin:0" >
              <div id="map"></div>
            </div>
      </CCol>
    </CRow>

    <CModal
      :show.sync="myerror"
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<style scoped>
  /* .leaflet-control-attribution a{
    display: none !important
  } */
.map-container {
  position: relative;
  width: 100%;
  height: 95vh;
}

.mapboxgl-canvas {
  position: absolute;
  width: 100%; 
  height: 95vh;
 }
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.marker:before {
  content: "";
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 75% 45% 75% 0%;
  background: #3498db;
  bottom: 0;
  transform-origin: 0% 100%;
  transform: rotate(-45deg) scale(1);
}

.mapboxgl-popup{
  position:absolute;
  left: 50%;
  top:50%;
}

/* .mapboxgl-popup-content .list-group-item {
  padding: 0.5rem 0.25rem;
}
.mapboxgl-popup-content .list-group-item .label {
  padding-right: 0.5rem;
  font-weight: bold;
} */
</style>

<script>
import axios from 'axios';
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  // components: {
  //   LMap,
  //   LTileLayer,
  //   LMarker,
  //   LPolygon,
  //   LGeoJson
  // },
  data () {
    return {
      nib : "",
      tipe_hak : "",
      opt : "PILIH KEL/DESA",
      kabkota: "PILIH KOTA/KAB" ,
      kelurahans:['PILIH KOTA/KAB','PONTIANAK','SINGKAWANG','BENGKAYANG','MEMPAWAH','MELAWI','KAYONG UTARA','KAPUAS HULU','KETAPANG','KUBU RAYA','LANDAK','SAMBAS','SANGGAU','SEKADAU','SINTANG'],
      su : "",
      latlngs: [[109.290056324599,-0.0124423568214555],[109.29122650719,-0.0160182734285108],[109.291621915063,-0.0158496126787141],[109.29045346265801,-0.0122778678589834],[109.290056324599,-0.0124423568214555]],
      nomor_hak : "",
      lemari : "",
      kolom : "",
      baris : "",
      ruangan : "",
      file : "",
      filename : "",
      myerror : false,
      placeholder : "Pilih File ",
      selected: [], // Must be an array reference!
      ruangans: [],
      lemaris: [],
      koloms: [],
      bariss: [],
      show: true,
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">BPN Kantah Kota Pontianak</a>',
      zoom: 12,
      center: [-0.0353948,109.2615099],
      coordinates:[],
      details:{},
      geojsonFile : {},
      geojs : null,
      geojs2 : null,
      idArray: []

    }
  },
   mounted(){
  // this.loadData();
  this.initMap();

    const response =  fetch('https://sigagah.s3.ap-southeast-1.amazonaws.com/data_baru.geojson');
    // axios.get("http://139.180.219.157/intip/data_baru.geojson")
    //     .then((response) => {
    //           this.geojs =response.data;
                        
    //     });

    // axios.get("http://139.180.219.157/intip/kec.geojson")
    //     .then((response) => {
    //           this.geojs2 =response.data;
                        
    //     });

    // this.details=JSON.parse(this.$store.state.properties);
    // var koor = JSON.parse(this.$store.state.coordinates);
    // var koor2=JSON.stringify(koor.substring( 3, koor.length - 3 ));
    // this.coordinates = koor2;
    // this.center=this.findCenter(koor2);
    // console.log("koor "+(this.$store.state.coordinates));


    // console.log(this.geojsonFile);

//     this.geojsonFile = {"type":"FeatureCollection", "features": [
// {"type":"Feature","geometry":{
//     "type":"Polygon",
//     "coordinates":[[[109.290056324599,-0.0124423568214555],[109.29122650719,-0.0160182734285108],[109.291621915063,-0.0158496126787141],[109.29045346265801,-0.0122778678589834],[109.290056324599,-0.0124423568214555]]]},
//     "properties":{"Join_Count":1,"TARGET_FID":126,"Join_Cou_1":1,"TARGET_F_1":126,"KANWIL":"Kalbar","KANTAH":"Kota Pontianak","KECAMATAN":"PONTIANAK BARAT","DESA":"SUNGAI BELIUNG","KODEWILAYA":"14010304","NIB":"09028","SU":"SU.07935/2015","HAK":"P.00280","TIPEHAK":"Hak Pakai","LUASTERTUL":19646,"LUASPETA":19826.11,"PRODUK":"","TAHUN":0,"PEMANFAATA":"","PENGGUNAAN":"","NIK":"-","PEMILIK":"PEMERINTAH PROPINSI DAERAH TINGKAT I KALIMANTAN BARAT","TEMPATLAHI":"","TANGGALLAH":"01/01/0001","ALAMAT":"-","TIPEPEMILI":"Instansi Pemerintah","No_1":9,"X_1":176763.43,"Y_1":1498239.82,"Kode_Lok_2":"11.01.61.00.300202.00000","Barang_1":"1.3.1.01.02.02.001","Kode_Lok_3":11.01,"Barang_D_1":1.3,"Register_1":0,"Nama_Bar_1":"Tanah Tegalan","Tanggal__1":"1989-12-31T00:00:00.000Z","Letak____1":"Jl. Nipah Kuning Dalam, Sungai Beliung, Pontianak","Luas__M²1":19646,"Sertifik_1":"1993-04-14T00:00:00.000Z","Nomor_1":"Hak Pakai No 280/Sungai Beliung /  GS No 7935/Sungai Beliung/2015","Harga_Pe_3":"59.238.000,00","Harga_Pe_4":0,"Harga_Pe_5":59.238,"Pagar_1":"tidak ada","Plang_Na_1":"ada","Patok_Ba_1":"ada","Dikuasai_1":"clean & clear","xwgs":109.290839434,"ywgs":-0.0141468,"x_tm3":176718.629278,"y_tm3":1498435.87343}}
// ]}

    // alert(JSON.stringify(this.geojsonFile));
    // var mymap = L.map(this.$refs['mapElement']).setView([51.505, -0.09], 13);
    // document.getElementsByClassName('leaflet-control-attribution').style.display = 'none';
    // map.attributionControl.setPrefix(false);
    // L.map('map', {attributionControl: false})
  // },

  // mounted() {
    // this.loadData();
    // this.$refs.mapElement.$mapPromise.then((map) => {
    //   // map.data.loadGeoJson(process.env.VUE_APP_BASE_URL+'batas.geojson');
    //   // map.data.loadGeoJson(process.env.VUE_APP_BASE_URL+'kec.geojson');
    // })
  },

  methods: {
    findCenter:function(markers) {
      let lat = 0;
      let lng = 0;
      
      for(let i = 0; i < markers.length; ++i) {
          lat += markers[i].lat;
          lng += markers[i].lng;
      }

      lat /= markers.length;
      lng /= markers.length;

      return [lat,lng]
  },
    loadData: function(event){


    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.filename = fileData.name;
      this.file = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },
    resetMarkers() {
      this.markers.forEach((marker) => {
        marker.remove();
      });
      this.markers = [];
    },
    updateMarkers() {
      this.markers = this.currentFeatures.features.map((feature) => {
        const el = document.createElement("div");
        el.className = "marker";

        // create the popup
        const popup = this.featurePopup(feature);
        return new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates).setPopup(popup).addTo(this.map);
      });
    },

    initMap() {
      // axios.get("http://139.180.219.157/intip/data_baru.geojson")
      //     .then((response) => {
      //           this.geojs =response.data;
      //       alert(this.geojs);
      // console.log(this.geojs);
                          
      //     });
  
      axios.get('https://sigagah.s3.ap-southeast-1.amazonaws.com/batas_kec.geojson')
          .then((response) => {
                this.geojs2 =response.data;
            // alert(this.geojs2);
      // console.log("geojsonbatas::",JSON.stringify(this.geojs2));
                          
          });
      mapboxgl.accessToken = "pk.eyJ1IjoibWluZ3lhbjIxNCIsImEiOiJja2h4Y2xiajAwMTBsMnZuNHBzMjVmYjlsIn0.w7Wxvvi2i4olHVL2gE1zkQ";
      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [109.2615099,-0.0353948],
        zoom: 11,
        // zoom: -5,
      });
      this.map.on("load", () => {
        this.map.addSource('batas', {
          'type': 'geojson',
          'data': 'https://sigagah.s3.ap-southeast-1.amazonaws.com/batas_kec.geojson'
          });
        this.map.addLayer({
          'id': 'batas',
          'type': 'fill',
          'source': 'batas',
          'paint': {
          'fill-color': '#888888',
          'fill-opacity': 0.2,
          'fill-outline-color': '#000',
          },
          });
          this.map.addSource('bersertifikat', {
          'type': 'geojson',
          'data': 'https://sigagah.s3.ap-southeast-1.amazonaws.com/data_baru.geojson'
          });
          this.map.addLayer({
          'id': 'bersertifikat',
          'type': 'fill',
          'source': 'bersertifikat',
          'layout': {
          // Make the layer visible by default.
          'visibility': 'visible'
          },
          'paint': {
          'fill-color': '#009900',
          },
          });
        this.map.addControl(new mapboxgl.NavigationControl(), "top-left");
        this.map.addControl(
          new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true,
            },
            trackUserLocation: true,
          }),
          "top-right"
        );
        this.map.addControl(
          new mapboxgl.ScaleControl({
            maxWidth: 100,
            unit: "metric",
          })
        );

        if (this.markers.length > 0) {
          this.resetMarkers();
        }

        if (this.currentFeatures) {
          this.updateMarkers();
        }
      });

      this.map.on('click', 'bersertifikat', (e) => {
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        var description = "<table class='table table-bordered'>"
          description += "<tr><td>SKPD</td><td>"+e.features[0].properties.SKPD+"</td></tr>";
          description += "<tr><td>NoHak</td><td>"+e.features[0].properties.NOMOR_HAK+"</td></tr>";
          description += "<tr><td>TipeHak</td><td>"+e.features[0].properties.TIPEHAK+"</td></tr>";
          description += "<tr><td>KodeBrg</td><td>"+e.features[0].properties.KodeBrg+"</td></tr>";
          description += "<tr><td>NamaBrg</td><td>"+e.features[0].properties.NamaBrg+"</td></tr>";
          description += "<tr><td>Kab/Kota</td><td>"+e.features[0].properties.Kab+"</td></tr>";
          description += "<tr><td>Kec</td><td>"+e.features[0].properties.KECAMATAN+"</td></tr>";
          description += "<tr><td>Kel/Des</td><td>"+e.features[0].properties.KELURAHAN+"</td></tr>";
          description += "</table>";
        
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        
        new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(description)
        .addTo(this.map);
        });

        this.map.on('click', 'belumbersertifikat', (e) => {
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        var description = "<table class='table table-bordered'>"
          description += "<tr><td>SKPD</td><td>"+e.features[0].properties.SKPD+"</td></tr>";
          description += "<tr><td>NoHak</td><td>"+e.features[0].properties.NOMOR_HAK+"</td></tr>";
          description += "<tr><td>TipeHak</td><td>"+e.features[0].properties.TIPE_HAK+"</td></tr>";
          description += "<tr><td>KodeBrg</td><td>"+e.features[0].properties.KodeBrg+"</td></tr>";
          description += "<tr><td>NamaBrg</td><td>"+e.features[0].properties.NamaBrg+"</td></tr>";
          description += "<tr><td>Kab/Kota</td><td>"+e.features[0].properties.Kab+"</td></tr>";
          description += "<tr><td>Kec</td><td>"+e.features[0].properties.KECAMATAN+"</td></tr>";
          description += "<tr><td>Kel/Des</td><td>"+e.features[0].properties.KELURAHAN+"</td></tr>";
          description += "</table>";
        
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        
        new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(description)
        .addTo(this.map);
        });

        this.map.on('click', 'hakdiatashak', (e) => {
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        var description = "<table class='table table-bordered'>"
          description += "<tr><td>Pemilik</td><td>"+e.features[0].properties.Nama_Pemil+"</td></tr>";
          description += "<tr><td>NoHak</td><td>"+e.features[0].properties.NOHAKBARU+"</td></tr>";
          description += "<tr><td>TipeHak</td><td>"+e.features[0].properties.TIPEHAK+"</td></tr>";
          description += "<tr><td>Kab/Kota</td><td>"+e.features[0].properties.Kab+"</td></tr>";
          description += "<tr><td>Kec</td><td>"+e.features[0].properties.KECAMATAN+"</td></tr>";
          description += "<tr><td>Kel/Des</td><td>"+e.features[0].properties.KELURAHAN+"</td></tr>";
          description += "<tr><td>Alamat</td><td>"+e.features[0].properties.ALAMAT+"</td></tr>";
          description += "<tr><td>L.Tertulis</td><td>"+e.features[0].properties.LUASTERTUL+"</td></tr>";
          description += "<tr><td>HakBerakhir</td><td>"+e.features[0].properties.hak_berakh+"</td></tr>";
          description += "<tr><td>HakPerpanjang</td><td>"+e.features[0].properties.Perpanjang+"</td></tr>";
          description += "<tr><td>Kategori</td><td>"+e.features[0].properties.kategori+"</td></tr>";
          description += "</table>";
        
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        
        new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setHTML(description)
        .addTo(this.map);
        });
    },
    addLayer(id, source, warna_isi, warna_garis, transparansi){
    
      if (this.map.getLayer(id)) {
        const visibility = this.map.getLayoutProperty(
        id,
        'visibility'
        );

        if (visibility === 'visible') {
        this.map.setLayoutProperty(id, 'visibility', 'none');
        this.className = '';
        } else {
        this.className = 'active';
        this.map.setLayoutProperty(
        id,
        'visibility',
        'visible'
        );
        }

      }
      else{
        this.map.addSource(id, {
          'type': 'geojson',
          'data':source
          });
        this.map.addLayer({
          'id': id,
          'type': 'fill',
          'source': id,
          'layout': {
          // Make the layer visible by default.
          'visibility': 'visible'
          },
          'paint': {
          'fill-color': warna_isi,
          'fill-opacity': transparansi,
          'fill-outline-color': warna_garis,
          },
          });

      }
      

      
    
    },
    featurePopup(feature) {
      return new mapboxgl.Popup({ offset: 25 }).setHTML(`
        <div class="card">
          <div class="card-body">
            <p class='feature-title font-weight-bold'>${feature.properties.project.Title}</p>

              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <span class='label'>Value($):</span>${Number(feature.properties.project.Value)} K
                </li>
                <li class="list-group-item">
                  <span class='label'>Address:</span>${feature.properties.project.Address}
                </li>
                <li class="list-group-item">
                  <span class='label'>Suburb:</span>${feature.properties.project.Suburb}
                </li>
                <li class="list-group-item">
                  <span class='label'>Floor Area(m2):</span>${Number(feature.properties.project["Floor Area"])}
                </li>
                <li class="list-group-item">
                  <span class='label'>Site Area(m2):</span>${Number(feature.properties.project["Site Area"])}
                </li>
                <li class="list-group-item">
                  <span class='label'>Stories:</span>${Number(feature.properties.project.Storeys)}
                </li>
                <li class="list-group-item">
                  <span class='label'>Units:</span>${Number(feature.properties.project.Units)}
                </li>
                <li class="list-group-item">
                  <span class='label'>Stage:</span>${feature.properties.project.Stage}
                </li>
                <li class="list-group-item">
                  <span class='label'>Category:</span>${feature.properties.project.Category}
                </li>
                <li class="list-group-item">
                  <span class='label'>Sub-category:</span>${feature.properties.project.SubCategory}
                </li>
                <li class="list-group-item">
                  <span class='label'>Category:</span>${feature.properties.project.Category}
                </li>
              </ul>
          </div>
        </div>
        `);
    },

  }
}
</script>